import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import AuthContext from "../context/authContext";

const StyledList = styled.div`
  padding-top: 0.5rem;
  margin-top: calc(var(--baseline) * 6);
  position: fixed;
  top: 0;
  right: 0;
  transform: ${(props) => (props.open ? "translateX(0)" : "translateX(100%)")};
  height: 100vh;
  width: 320px;
  transition: all 0.2s ease-in;
  background: var(--color-solar);
  z-index: 99;
  border-left: 1px solid #000;
`;

const MenuWrapper = styled.div``;

const StyledLink = styled(Link)`
  position: relative;
  font-size: var(--baseline);
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5rem 0.5rem;
  margin-top: 0.5rem;
  /* border-bottom: 1px solid #000; */
  display: block;
  line-height: 1;
  padding-left: calc(2 * var(--baseline));
  &:hover::after {
    transform: scaleX(1);
  }
  &::after {
    content: "";
    display: block;
    width: 50%;
    height: 1px;
    position: absolute;
    bottom: 0;
    background: #000;
    transform: scaleX(0);
    transform-origin: 0;
    transition: transform 0.4s ease;
  }
`;

const StyledButton = styled.button`
  font-size: var(--baseline);
  background: none;
  width: 100%;
  border: none;
  display: block;
  text-transform: uppercase;
  font-family: "PT Mono", monospace;
  padding: 1rem 0.5rem;
  text-align: left;
  border-color: ${({ theme }) => theme.text};
  text-decoration: none;
  padding-left: calc(var(--baseline) * 2);
  color: var(--color-black);
  cursor: pointer;
`;

export default function Menu({ isOpen, setClose }) {
  const user = useContext(AuthContext);

  return (
    <MenuWrapper>
      <StyledList open={isOpen}>
        <nav>
          {user.getUser() ? (
            <>
              <StyledLink to='/dashboard'>Админка</StyledLink>
              <StyledLink to='/create'>Добавить ивент</StyledLink>
            </>
          ) : null}
          <StyledLink to='/archive'>Архив</StyledLink>
          {user.getUser() ? (
            <StyledButton onClick={() => user.logOut()}>Выйти</StyledButton>
          ) : (
            <StyledLink to='/login'>Войти</StyledLink>
          )}
        </nav>
      </StyledList>
    </MenuWrapper>
  );
}
