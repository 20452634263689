import React from 'react';
import Layout from '../components/layout';
import Form from '../components/createEventForm';

const MainPage = () => {
    return (
        <Layout>
            <Form />
        </Layout>
    )
}

export default MainPage
