import React, { useState } from "react";
import Modal from "react-modal";

import moment from "moment";
import styled from "styled-components";

import "moment/locale/ru";

import EditEventForm from "./editEventForm";

moment.locale("ru");
Modal.setAppElement("#root");

const StyledCard = styled.div`
  border-bottom: 1px solid #0e131f;
  border-color: ${({ theme }) => theme.border};
  position: relative;
  transition: all 230ms ease;
  line-height: 120%;
  padding: 0;
  overflow: hidden;

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 780px) {
    padding-top: 1em;
    font-size: 12px;
    grid-template-columns: 1fr 1fr;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: min-content 1fr min-content;
  max-width: 1200px;
  margin: 0 auto;
`;

const StyledHeader = styled.h3`
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-size: 1rem;
  line-height: var(--baseline);
  margin-bottom: 0.5em;
  font-weight: 400px;

  @media (max-width: 780px) {
    font-size: 1rem;
  }
`;

const StyledDate = styled.p`
  margin: 0;
  padding: 0;
  line-height: 120%;
  font-size: 0.8rem;
  font-family: "PT Mono", monospace;
  text-transform: uppercase;
`;

const Indicator = styled.div`
  margin: 0;
  width: 30px;
  height: 100%;
  background: ${(props) => props.color};
`;

const Info = styled.div`
  padding-right: 1rem;
  margin-left: 8px;
  padding: 0.5em;
`;

const EditButton = styled.button`
  background: none;
  border: none;
  height: 100%;
  text-transform: uppercase;
  font-family: "PT Mono", monospace;
  font-size: 0.8em;
  padding-left: 8px;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  transition: all 230ms ease;

  :hover {
    background-color: #e9b872;
  }
`;

export default function DashboardCard({ values }) {
  const { title, date, city, link, published } = values;
  const formatedDate = moment(date).format("LL").slice(0, -8);
  const [edit, setEdit] = useState(false);

  const modalStyle = {
    content: {
      top: "80px",
      borderRadius: "none",
    },
  };

  const renderModal = () => {
    return (
      <Modal isOpen={edit} style={modalStyle}>
        <EditEventForm
          values={values}
          contentLabel="Example Modal"
          onClose={() => {
            setEdit(false);
          }}
        />
      </Modal>
    );
  };

  return (
    <>
      {edit && renderModal()}
      <StyledCard>
        <Wrapper>
          <Indicator color={published ? "#BDE4A7" : "#ea9010"} />
          <Info>
            <StyledHeader>
              {title}{" "}
              <a href={link} target="blank">
                ↗
              </a>
            </StyledHeader>
            <StyledDate>
              {formatedDate} ({city})
            </StyledDate>
          </Info>
          <EditButton onClick={() => setEdit(!edit)}>редактировать</EditButton>
        </Wrapper>
      </StyledCard>
    </>
  );
}
