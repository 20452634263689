import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getAllEvents } from "../services/events";
import Layout from "../components/layout";
import DashboardCard from "../components/dashboardCard";

const EventsList = styled.div`
  height: calc(100vh - 156px);
  overflow-y: scroll;
  overflow-x: hidden;
  border-top: 1px solid #0e131f;
`;

const Paginator = styled.div`
  padding: 24px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 28px;
  font-family: monospace;

  & p {
    display: block;
    padding: 0 8px;
    font-size: 16px;
    line-height: 1;
    margin: auto;
  }

  & button {
    font-family: monospace;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  & .buttons {
    display: flex;
  }
`;

const DashBoard = () => {
  const [events, setEvents] = useState([]);
  const [paginator, setPaginator] = useState({});

  const getEvents = (pageNum) => {
    getAllEvents(pageNum)
      .then((res) => {
        if (res.totalDocs) {
          setEvents(res.docs);
          setPaginator({
            all: res.totalDocs,
            hasNextPage: res.hasNextPage,
            hasPrevPage: res.hasPrevPage,
            page: res.page
          });
        } else {
          throw new Error("В ближайшее время гонок не ожидается...");
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Paginator>
        <div>All events</div>
        <div className='buttons'>
          <button
            disabled={!paginator.hasPrevPage}
            onClick={() => getEvents(paginator.page - 1)}
          >
            PREW
          </button>
          <p>
            {paginator.page}/{Math.ceil(paginator.all / 10) || ""}
          </p>
          <button
            disabled={!paginator.hasNextPage}
            onClick={() => getEvents(paginator.page + 1)}
          >
            NEXT
          </button>
        </div>
      </Paginator>
      <EventsList>
        {events.map((e) => (
          <DashboardCard values={e} />
        ))}
      </EventsList>
    </Layout>
  );
};

export default DashBoard;
