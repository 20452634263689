import styled from 'styled-components'

export const Form = styled.form`
  & input {
    font-size: 1.5em;
    font-family: 'PT Mono', monospace;
    max-width: 100%;
    min-width: 100%;
    padding: 0.5rem;
    border: 1px solid black;
    margin-bottom: 0.5rem;
  }

  & label {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: 'PT Mono', monospace;
    margin-bottom: 0.5rem;
  }
  & button {
    background: none;
    border: 1px solid black;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-family: 'PT Mono', monospace;
    font-size: 1em;
    &:hover {
      background: black;
      color: white;
  }
  }
}`