import React, { useState } from "react";
import styled from "styled-components";
import CreateEventForm from "../components/createEventForm";

const StyledOffer = styled.div`
  position: fixed;
  bottom: 80px;
  left: 80px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  transform: scale(0.9);
  transition: all 250ms ease;

  &:hover {
    transform: scale(1.3);
  }

  @media (max-width: 780px) {
    bottom: 90px;
    right: 24px;
  }

  & button {
    width: 100%;
    height: 100%;
    border: none;
    color: white;
    background: var(--color-green);
    font-size: 2em;
    line-height: 0;
    cursor: pointer;
    transform: rotate(1deg);
    animation: spin 2s linear infinite;
  }
`;

const CloseButton = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  font-size: 2rem;
  transform: rotate(45deg);
  transition: all 300ms ease;

  &:hover {
    transform: rotate(-45deg);
  }
`;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  transform: ${(props) =>
    props.isVisible ? "translateX(0%)" : "translateX(100%)"};
  width: 50vw;
  height: 100%;
  margin-top: calc(var(--baseline) * 6);
  max-height: calc(100% - var(--baseline) * 8);
  max-width: calc(600px + 4em);
  overflow: hidden;
  color: white;
  overflow-y: scroll;

  background: var(--color-solar);
  color: var(--color-black);
  border-left: 1px solid var(--color-black);

  transition: all 300ms ease;

  @media (max-width: 780px) {
    width: 100vw;
  }

  & input {
    border: 1px solid var(--color-black);
    outline: none;
    border-radius: 0.5em;
  }

  & textarea {
    border: none;
    outline: none;
    border-radius: 0.2em;
  }

  & button {
    border: 2px solid var(--color-black);
    border-radius: 0.5em;
    cursor: pointer;
    color: var(--color-black);
  }
`;

export default function OfferButton(action) {
  const [modalVisible, setModalVisible] = useState(false);

  const onClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <StyledOffer>
        <button className="offer__button" onClick={() => setModalVisible(true)}>
          +
        </button>
      </StyledOffer>
      <StyledModal isVisible={modalVisible}>
        <CloseButton onClick={onClose}>+</CloseButton>
        <CreateEventForm
          moderation
          submitCallback={() => setModalVisible(false)}
        />
      </StyledModal>
    </>
  );
}
