import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
`;

export default function Container({ children }) {
  return <StyledContainer>{children}</StyledContainer>;
}
