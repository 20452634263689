import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import AuthContext from "./context/authContext";
import Firebase from "./services/firebase";

ReactDOM.render(
  <AuthContext.Provider value={new Firebase()}>
    <App />
  </AuthContext.Provider>,
  document.getElementById("root")
);
