import { createGlobalStyle } from 'styled-components';

import MistoWoff from '../assets/fonts/Misto.woff';
import MistoWoff2 from '../assets/fonts/Misto.woff2';
import PTMonoWoff from '../assets/fonts/PTMono-Regular.woff';
import PTMonoWoff2 from '../assets/fonts/PTMono-Regular.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Misto';
        src: local('Misto'), local('Misto'),
            url(${MistoWoff2}) format('woff2'),
            url(${MistoWoff}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
    font-family: 'PT Mono';
    src: local('PTMono-Regular'), local('MPTMono-Regular'),
        url(${PTMonoWoff2}) format('woff2'),
        url(${PTMonoWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

`;
