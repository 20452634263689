import React from "react";
import Card from "./card";
import Loader from "../components/loader";

import s from "../styles/card-list.module.css";

export default function CardList({ events, error }) {
  return (
    <div className={s.cardsListContainer}>
      {/* <div className={s.cardsListContainer__title}>Ближайшие ивенты:</div> */}
      <div className={s.cardsListContainer__content}>
        {error && <p className={s.cardsList__error}>{error.message}</p>}
        {!events.length
          ? !error && <Loader />
          : events.map((values) => <Card key={values._id} values={values} />)}
      </div>
    </div>
  );
}
