import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";
import MDEditor from "@uiw/react-md-editor";
import { useHistory } from "react-router";
import rehypeSanitize from "rehype-sanitize";

import "moment/locale/ru";
import EditEventForm from "./editEventForm";
// import AuthContext from "../context/authContext";
// import { deleteEvent } from '../services/events';

moment.locale("ru");

const StyledCard = styled.article`
  padding: 1em;
  border-bottom: 1px solid #0e131f;
  border-color: ${({ theme }) => theme.border};
  display: grid;
  grid-template-columns: 2fr 2fr 3fr;
  grid-template-rows: 1fr min-content min-content;
  gap: 1em;
  grid-column: 2/7;
  position: relative;
  transition: all 300ms ease;
  line-height: 120%;
  overflow: hidden;

  @media (max-width: 780px) {
    padding-top: 1em;
    font-size: 12px;
    grid-template-rows: min-content 1fr min-content;
    grid-template-columns: 1fr 1fr;
  }
`;

const StyledHeader = styled.h3`
  text-decoration: underline;
  font-family: "misto";
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-size: 2.8rem;
  line-height: 120%;
  margin-bottom: 1rem;
  font-weight: 600px;
  grid-column: 1/1;

  @media (max-width: 780px) {
    font-size: 1rem;
    grid-rows: 1/1;
    grid-columns: 1/2;
  }

  &:hover {
    font-style: italic;
  }
`;

const StyledDate = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem;
  line-height: 120%;
  font-size: 0.8rem;
  font-family: "PT Mono", monospace;
  text-transform: uppercase;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  padding-right: 1rem;
  font-family: "PT Mono", monospace;
  padding-top: 0;
  grid-column: 3/4;
  max-width: 600px;

  & .wmde-markdown,
  & pre,
  & code {
    background: transparent;
    font-family: "PT Mono", monospace;
  }

  @media (max-width: 780px) {
    font-size: 12px;
    grid-row: 2/2;
    grid-column: 1/3;
  }
`;

const Poster = styled.div`
  overflow: hidden;
  grid-row: 1/2;
  grid-column: 2/2;
  max-width: 400px;
  & img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 780px) {
    grid-row: 1/2;
    grid-column: 2/3;
  }
`;

const Info = styled.div`
  grid-column: 1/1;

  @media (max-width: 780px) {
    font-size: 1rem;
    grid-row: 1/1;
    grid-column: 1/2;
  } ;
`;

const Host = styled.p`
  padding-top: 2em;
  margin-top: auto;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "PT Mono", monospace;
  font-size: 0.8rem;
  grid-column: 1/3;
  grid-row-start: 3;
  align-self: start;
`;

const Price = styled.div`
  border-radius: 50%;
  text-align: center;
  max-width: 15rem;
  position: absolute;
  padding: 2rem 3rem;
  text-transform: uppercase;
  font-family: "PT Mono", monospace;
  font-weight: 600;
  bottom: 2rem;
  right: 1rem;
  transform: rotate(15deg);
  border: 2px solid #ff6700;
  color: #ff6700;
  font-size: 3rem;

  @media (max-width: 780px) {
    padding: 1rem 2rem;
    font-size: 2rem;
    bottom: 1rem;
    right: 0.5rem;
  }
`;

// const EditButton = styled.button`
//   background: none;
//   border: none;
//   text-decoration: underline;
//   padding: 0.5rem;
//   text-transform: uppercase;
//   font-family: 'PT Mono', monospace;
//   font-size: 0.8em;
//   cursor: pointer;

//   &:hover {
//     font-style: italic;
//   }
// `;

export default function Card({ values }) {
  const { title, description, date, image, city, link, host, price } = values;
  const formatedDate = moment(date).format("LL").slice(0, -8);
  const fromNow = moment(date).fromNow();
  const [edit, setEdit] = useState(false);
  const history = useHistory();

  return (
    <>
      {edit ? (
        <EditEventForm
          values={values}
          onClose={() => {
            setEdit(false);
            history.go(0);
          }}
        />
      ) : (
        <StyledCard>
          <Info>
            <StyledHeader>{title} </StyledHeader>
            {link && (
              <a href={link} target="blank">
                ↗
              </a>
            )}
            <StyledDate>{`${formatedDate} (${fromNow})`}</StyledDate>
            <StyledDate>{city}</StyledDate>
          </Info>
          <Description>
            <MDEditor.Markdown
              source={description}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
            />
          </Description>
          <Poster>
            <img src={image} alt={`Poster for ${title}`} />
          </Poster>
          {host && <Host>Организатор: {host}</Host>}
          {price && <Price>{price}₽</Price>}
        </StyledCard>
      )}
    </>
  );
}
