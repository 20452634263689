import * as firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
};

class Firebase {
    constructor() {
        firebase.initializeApp(firebaseConfig);

        this.auth = firebase.auth();
        this.userUid = null;
    }

    getUser = () => (
        !!window.localStorage.getItem('user')
    )

    setUserUid = (uid) => (this.userUid = uid);

    signIn = (email, pass) =>
        this.auth.signInWithEmailAndPassword(email, pass);

    createNewUser = (email, pass) =>
        this.auth.createUserWithEmailAndPassword(email, pass);

    logOut = () => {
        this.auth.signOut();
        this.userUid = null;
        window.localStorage.removeItem('user')
    };
}

export default Firebase;
