import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import AuthContext from '../context/authContext';
import { useHistory } from 'react-router-dom';

import { Form } from './shared/form';
import { Button } from './shared/button';
import { H3 } from './shared/heading';

const Error = styled.div`
  text-transform: uppercase;
  font-family: 'PT Mono', monospace;
  font-size: 1rem;
  color: var(--color-orange);
  margin-top: 1rem;
`;

const Login = () => {
  const history = useHistory();
  const [user, setUser] = useState({ login: '', password: '' });
  const [error, setError] = useState(null);

  const userContext = useContext(AuthContext);

  useEffect(() => {
    const { auth, setUserUid } = userContext;
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUserUid(user.uid);
        localStorage.setItem('user', JSON.stringify(user.uid));
        history.push('/');
      } else {
        setUserUid(null);
        localStorage.removeItem('user');
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    const { email, password } = user;
    const { signIn, setUserUid } = userContext;

    signIn(email, password)
      .then((res) => {
        setUserUid(res.user.uid);
        localStorage.setItem('user', JSON.stringify(res.user));
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const renderLoginForm = () => {
    return (
      <Form onSubmit={(e) => onSubmit(e)}>
        <H3>Вход</H3>

        <label>логин</label>
        <input
          type="email"
          label="Email"
          name="email"
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />

        <label>пароль</label>
        <input
          type="password"
          label="Email"
          name="email"
          onChange={(e) => setUser({ ...user, password: e.target.value })}
        />
        <Button htmltype="submit">Войти</Button>
        {error && <Error className="error">{error}</Error>}
      </Form>
    );
  };

  return (
    <div>
      <div>{renderLoginForm()}</div>
    </div>
  );
};

export default Login;
