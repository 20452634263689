import styled from 'styled-components';

export const Button = styled.button`
    background: none;
    border: 1px solid black;
    padding: 0.5rem 0;
    text-transform: uppercase;
    font-family: 'PT Mono', monospace;
    font-size: 2rem;
    width: 100%;

    &:hover {
        background: black;
        color: white;
    }
`;