import React, { useState, useEffect } from "react";

import { getActualEvents } from "../services/events";
import Layout from "../components/layout";
import CardList from "../components/cardList";
import OfferButton from "../components/offer-button";

const MainPage = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    getActualEvents()
      .then((data) => {
        if (data.length) {
          setEvents(data.sort((a, b) => new Date(a.date) - new Date(b.date)));
        } else {
          throw new Error("В ближайшее время гонок не ожидается...");
        }
      })
      .catch((error) => setError(error));
  }, []);

  return (
    <>
      <Layout>
        {/* <Featured /> */}
        <CardList events={events} error={error} />
        <OfferButton />
      </Layout>
    </>
  );
};

export default MainPage;
