export const lightTheme = {
  body: "#FCFBF2",
  text: "#182635",
  border: "#0e131f",
  //   toggleBorder: '#FFF',
  gradient: `linear-gradient(
    180deg,
    rgba(163, 255, 189, 1) 9%,
    rgba(255, 248, 174, 1) 88%
  );`,
};

export const darkTheme = {
  body: "#182635",
  text: "#FAFAFA",
  border: "#FAFAFA",
  //   toggleBorder: '#6B8096',
  gradient: "linear-gradient(#091236, #1E215D)",
};
