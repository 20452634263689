import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  border-top: 1px solid var(--color-black);
  border-color: ${({ theme }) => theme.border};
  max-height: calc(var(--baseline) * 2);
  padding: 1rem 2rem;
  width: 100%;
  font-size: calc(var(--baseline)/1.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-family: 'PT Mono', monospace;
  font-weight: 900;
  position: sticky;
  color: ${({ theme }) => theme.text};
 
  top: 0;
  z-index: 0;
  height: 65px;
`;

export default function footer() {
  return (
    <StyledFooter>
      <a href="https://github.com/sanyacastor" target='blank'>Sanya 3000 ©</a>
    </StyledFooter>
  );
}
