import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { H2 } from "../components/shared/heading";
import Container from "../components/shared/container";

import rehypeSanitize from "rehype-sanitize";
import MDEditor from "@uiw/react-md-editor";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: calc(var(--baseline) * 2);
  max-width: 600px;

  & h2 {
    font-family: "PT Mono", monospace;
  }

  & input {
    font-size: 1rem;
    font-family: "PT Mono", monospace;
    max-width: 100%;
    min-width: 100%;
    padding: 0.5rem;
    border: 1px solid black;
    margin-bottom: 0.5rem;
    max-height: calc(var(--baseline) * 2);
  }

  & .wmde-markdown {
    font-family: "PT Mono", monospace !important;
    font-size: 16px !important;
  }

  & input[type="checkbox"] {
    max-width: auto;
    min-width: auto;
    align-self: start;
  }

  & label {
    text-transform: uppercase;
    font-family: "PT Mono", monospace;
  }
`;

const Button = styled.button`
  background: none;
  border: 1px solid black;
  padding: 0.5rem 0;
  text-transform: uppercase;
  font-family: "PT Mono", monospace;
  font-size: 1.5em;
  margin-top: 0.5em;
  color: var(--color-black);
`;

const Error = styled.div`
  font-size: 1rem;
  color: var(--color-orange);
`;

export default function CreateEventForm({ moderation, submitCallback }) {
  const history = useHistory();
  const createUrl = `${process.env.REACT_APP_API_URL}/events`;
  const [description, setDescription] = React.useState(
    "**ого! теперь есть поддержка маркдауна!**"
  );

  const formik = useFormik({
    initialValues: {
      title: "",
      host: "",
      city: "",
      description,
      image: "",
      link: "",
      date: "",
      published: false,
      price: "free",
    },

    validationSchema: Yup.object({
      title: Yup.string()
        .min(5, "Название события должно быть больше 5 символов")
        .max(35, "Слашком длинное название, максимум 35 символов")
        .required("Заполните название"),
      city: Yup.string().required("Укажите город"),
      date: Yup.date()
        .min(new Date(Date.now()), "Нельзя добавить прошедшее событие")
        .required("Зали указать дату события"),
      description: Yup.string().required("Добавьте пару слов о событии"),
      image: Yup.string().url("Пока тут можно вставлять только ссылки"),
      link: Yup.string().url("Это не похоже на ссылку"),
      published: Yup.bool(),
    }),

    onSubmit: (values) => {
      axios
        .post(createUrl, values)
        .then(() => {
          submitCallback();
          formik.resetForm();
          history.push("/");
        })
        .catch((error) => console.log(error));
    },
  });

  return (
    <Container>
      <H2>Добавить событие</H2>
      <StyledForm onSubmit={formik.handleSubmit}>
        <label htmlFor="title">*Название:</label>
        <input id="title" type="text" {...formik.getFieldProps("title")} />
        {formik.touched.title && formik.errors.title ? (
          <Error>{formik.errors.title}</Error>
        ) : null}
        {formik.touched.host && formik.errors.host ? (
          <Error>{formik.errors.host}</Error>
        ) : null}
        <label htmlFor="city">*Город:</label>
        <input id="city" type="text" {...formik.getFieldProps("city")} />
        {formik.touched.city && formik.errors.city ? (
          <Error>{formik.errors.city}</Error>
        ) : null}
        <label htmlFor="date">*Дата</label>
        <input id="date" type="date" {...formik.getFieldProps("date")} />
        {formik.touched.date && formik.errors.date ? (
          <Error>{formik.errors.date}</Error>
        ) : null}
        <label htmlFor="price">Цена участия</label>
        <input id="price" type="text" {...formik.getFieldProps("price")} />
        {formik.touched.price && formik.errors.price ? (
          <Error>{formik.errors.price}</Error>
        ) : null}
        <label htmlFor="image">Ссылка на постер/афишу</label>
        <input id="image" type="text" {...formik.getFieldProps("image")} />
        {formik.touched.image && formik.errors.image ? (
          <Error>{formik.errors.image}</Error>
        ) : null}
        <label htmlFor="link">Ссылка на ивент в соцсетях</label>
        <input id="link" type="text" {...formik.getFieldProps("link")} />
        {formik.touched.link && formik.errors.link ? (
          <Error>{formik.errors.link}</Error>
        ) : null}
        <label htmlFor="description">Описание</label>
        <MDEditor
          id="description"
          value={description}
          onChange={setDescription}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
          hideToolbar
        />
        {formik.touched.description && formik.errors.description ? (
          <Error>{formik.errors.description}</Error>
        ) : null}
        {moderation ? null : (
          <label htmlFor="published">
            <input
              id="published"
              type="checkbox"
              {...formik.getFieldProps("published")}
            />
            Опубликовано
          </label>
        )}
        <Button type="submit">{moderation ? "Предложить" : "Создать"}</Button>
      </StyledForm>
    </Container>
  );
}
