import React from 'react';
import Layout from '../components/layout';
import Login from '../components/login';

import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    .login {
        width: 50%;
        max-width: 550px;
    }
`

const MainPage = () => {
    return (
        <Layout>
            <Container>
                <div className='login'>
                <Login />
                </div>
            </Container>
        </Layout>
    )
}

export default MainPage
