import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root {
        --color-solar: #FCFBF2;
        --color-green: #BDE4A7;
        --color-black: #080f0f;
        --color-orange: #ea9010;
		    --baseline: 18px;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        padding: 0;
        font-size: 18px;
        line-height: calc(var(--baseline)*2);
        font-family: "sans-serif";
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};

        @media (max-width: 780px) {
            font-size: 16px;
        }
    }

    a {
        text-decoration: none;
        color: var(--color-black);
    }

`;

export default GlobalStyle;
