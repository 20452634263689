import styled from 'styled-components'

export const H3 = styled.h3`
    font-size: 2rem;
    text-transform: uppercase;
    font-family: 'PT Mono', monospace;
`

export const H2 = styled.h2`
    font-size: 1.6rem;
    text-transform: uppercase;
    font-family: 'PT Mono', monospace;
`