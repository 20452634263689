import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";

import Header from "./header";
import Footer from "./footer";

import GlobalStyle from "../styles/globalStyles";
import GlobalFonts from "../styles/globalFonts";
import { lightTheme, darkTheme } from "../styles/theme";

const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: calc(100vh - var(--baseline) * 8);
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: ${(props) => props.align};

  @media (max-width: 780px) {
    /* padding: 0 1rem; */
  }

  a {
    color: var(--color-black);
    font-weight: bold;
  }

`;

export default function Layout({ children, align }) {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  };

  return (
    <ThemeProvider
      theme={localStorage.getItem("theme") === "dark" ? darkTheme : lightTheme}
    >
      <GlobalFonts />
      <GlobalStyle />
      <Header
        onChangeTheme={toggleTheme}
        theme={localStorage.getItem("theme")}
      />
      <Wrapper align={align || "column"}>{children}</Wrapper>
      <Footer />
    </ThemeProvider>
  );
}
