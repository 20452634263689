import React, {useState} from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";

import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

import { Button } from "../components/shared/button";

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  position: relative;
  padding: 1rem;
  max-width: 650px;
  margin: 0 auto;

  & h2 {
    font-size: 1.5rem;
    font-weight: 300;
    font-family: "PT Mono", monospace;
    padding-top: 0;
    margin: 0;
    margin-bottom: 0.8rem;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: calc(var(--baseline) * 2);

  & h2 {
    font-family: "PT Mono", monospace;
  }

  & input {
    font-size: 1rem;
    font-family: "PT Mono", monospace;
    max-width: 100%;
    min-width: 100%;
    padding: 0.5rem;
    background: transparent;
    border: 1px solid black;
    border-color: ${({ theme }) => theme.border};
    color: ${({ theme }) => theme.text};
    max-height: calc(var(--baseline) * 2);
    &::selection {
      color: black;
      background: white;
    }
  }

  & .wmde-markdown {
    font-family: "PT Mono", monospace;
  }

  & input[type="checkbox"] {
    max-width: auto;
    min-width: auto;
    align-self: start;
  }

  & label {
    text-transform: uppercase;
    font-family: "PT Mono", monospace;
  }
`;

const Error = styled.div`
  font-size: 1em;
  text-transform: uppercase;
  font-family: "PT Mono", monospace;
  color: var(--color-orange);
`;

const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  font-size: 2rem;
  transform: rotate(45deg);
  transition: all 300ms ease;

  &:hover {
    transform: rotate(-45deg);
  }
`;

export default function EditEventForm({ values, onClose }) {
  const {
    _id,
    date,
    title,
    host,
    city,
    description,
    image,
    link,
    price,
    published,
  } = values;

  const isoDate = new Date(date).toISOString().slice(0, 10);
  const updateUrl = `${process.env.REACT_APP_API_URL}/events/${_id}`;
  const [descriptionValue, setDescriptionValue] = useState(description);

  const formik = useFormik({
    initialValues: {
      title,
      host,
      city,
      description: descriptionValue,
      image,
      link,
      price,
      published,
      date: isoDate,
    },

    validationSchema: Yup.object({
      title: Yup.string()
        .min(5, "Название события должно быть больше 5 символов")
        .max(35, "Слашком длинное название, максимум 35 символов")
        .required("Заполните название"),
      host: Yup.string()
        .max(20, "Имя должно быть не больше 20 символов")
        .required("Забыли упомянуть организатора"),
      city: Yup.string().required("Укажите город"),
      date: Yup.date().required("Зали указать дату события"),
      description: Yup.string().required("Добавьте пару слов о событии"),
      image: Yup.string().url("Пока тут можно вставлять только ссылки"),
      link: Yup.string().url("Это не похоже на ссылку"),
      published: Yup.bool(),
    }),

    onSubmit: (values) => {
      axios
        .put(updateUrl, { ...values, description: descriptionValue })
        .then((data) => onClose());
    },
  });

  return (
    <Wrapper>
      <Container>
        <h2>Редактировать событие</h2>
        <StyledForm onSubmit={formik.handleSubmit}>
          <label htmlFor="title">*Название:</label>
          <input id="title" type="text" {...formik.getFieldProps("title")} />

          {formik.touched.title && formik.errors.title ? (
            <Error>{formik.errors.title}</Error>
          ) : null}

          <label htmlFor="title">*Организтор:</label>
          <input id="host" type="text" {...formik.getFieldProps("host")} />

          {formik.touched.host && formik.errors.host ? (
            <Error>{formik.errors.host}</Error>
          ) : null}

          <label htmlFor="city">*Город:</label>
          <input id="city" type="text" {...formik.getFieldProps("city")} />

          {formik.touched.city && formik.errors.city ? (
            <Error>{formik.errors.city}</Error>
          ) : null}

          <label htmlFor="date">*Дата</label>
          <input id="date" type="date" {...formik.getFieldProps("date")} />

          {formik.touched.date && formik.errors.date ? (
            <Error>{formik.errors.date}</Error>
          ) : null}

          <label htmlFor="price">Цена участия</label>
          <input id="price" type="text" {...formik.getFieldProps("price")} />

          {formik.touched.price && formik.errors.price ? (
            <Error>{formik.errors.price}</Error>
          ) : null}

          <label htmlFor="image">Ссылка на постер/афишу</label>
          <input id="image" type="text" {...formik.getFieldProps("image")} />

          {formik.touched.image && formik.errors.image ? (
            <Error>{formik.errors.image}</Error>
          ) : null}

          <label htmlFor="link">Ссылка на ивент в соцсетях</label>
          <input id="link" type="text" {...formik.getFieldProps("link")} />

          {formik.touched.link && formik.errors.link ? (
            <Error>{formik.errors.link}</Error>
          ) : null}

          <label htmlFor="description">Описание</label>
          <MDEditor
            value={descriptionValue}
            onChange={setDescriptionValue}
            previewOptions={{
              rehypePlugins: [[rehypeSanitize]],
            }}
            hideToolbar
          />

          <label htmlFor="published">
            <input
              id="published"
              type="checkbox"
              defaultChecked={published}
              {...formik.getFieldProps("published")}
            />
            Опубликовано
          </label>
          {formik.touched.published && formik.errors.published ? (
            <Error>{formik.errors.published}</Error>
          ) : null}

          <Button type="submit">Обновить</Button>
          <CloseButton onClick={onClose}>+</CloseButton>
        </StyledForm>
      </Container>
    </Wrapper>
  );
}
