import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Navicon, Close } from "@styled-icons/evil";
import Menu from "../components/menu.jsx";

import logo from "../assets/img/dfl-logo.svg";

const StyledHeader = styled.div`
  height: calc(var(--baseline) * 6);
  border-bottom: 1px solid blue;
  border-color: ${({ theme }) => theme.border};
  text-transform: uppercase;
  font-family: "PT Mono", monospace;
  font-weight: 900;
  display: flex;
  position: sticky;
  align-items: center;
  padding: 1rem 2rem;
  background: ${({ theme }) => theme.body};
  top: 0;
  z-index: 2;
`;

const LinksList = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .links {
    display: flex;
    position: absolute;
    right: 2rem;
  }
`;

const MenuButton = styled(Navicon)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  line-height: 1;
  border: none;
  border-radius: 50%;
  font-size: 30px;
  color: ${({ theme }) => theme.text};
`;

const CloseButton = styled(Close)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  line-height: 1;
  border: none;
  border-radius: 50%;
  font-size: 30px;
  color: ${({ theme }) => theme.text};
`;

export default function Header({ onChangeTheme, theme }) {
  const [open, setOpen] = useState(false);

  return (
    <StyledHeader>
      <LinksList>
        <Link to="/">
          <img src={logo} alt="dfl logo" />
        </Link>
        <div className="links">
          {open ? (
            <CloseButton onClick={() => setOpen(!open)}></CloseButton>
          ) : (
            <MenuButton onClick={() => setOpen(!open)}></MenuButton>
          )}
        </div>
      </LinksList>
      <Menu isOpen={open} setClose={() => setOpen(false)} />
    </StyledHeader>
  );
}
