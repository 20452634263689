import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:5000/api";

export const getActualEvents = async () => {
  try {
    const data = await axios.get(`${baseUrl}/events/actual`);
    return data.data;
  } catch (e) {
    throw new Error("Ошибка загрузки данных");
  }
};

export const getArchiveEvents = async (pageNumber = 1) => {
  try {
    const data = await axios.get(`${baseUrl}/events/passed?page=${pageNumber}`);
    return data.data;
  } catch (e) {
    throw new Error("Ошибка загрузки данных");
  }
};

export const getAllEvents = async (pageNumber = 1) => {
  try {
    const data = await axios.get(`${baseUrl}/events/all?page=${pageNumber}`);
    return data.data;
  } catch (e) {
    throw new Error("Ошибка загрузки данных");
  }
};

export const getOneEvent = async (id) => {
  axios
    .get(`${baseUrl}/events/${id}`)
    .then((data) => {
      return data.data;
    })
    .catch((e) => console.log(e));
};

export const deleteEvent = async (id) => {
  axios
    .delete(`${baseUrl}/events/${id}`)
    .then((data) => {
      return data.data;
    })
    .catch((e) => console.log(e));
};
