import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getArchiveEvents } from "../services/events";
import Layout from "../components/layout";
import CardList from "../components/cardList";
import { H2 } from "../components/shared/heading";
import Container from "../components/shared/container";

const Paginator = styled.div`
  padding: 24px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 28px;
  font-family: monospace;

  & p {
    display: block;
    padding: 0 8px;
    font-size: 16px;
    line-height: 1;
    margin: auto;
  }

  & button {
    font-family: monospace;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  & .buttons {
    display: flex;
  }
`;

const ArchivePage = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [paginator, setPaginator] = useState({});

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = (pageNum) =>
    getArchiveEvents(pageNum)
      .then((res) => {
        if (res.docs.length) {
          setPaginator({
            all: res.totalDocs,
            hasNextPage: res.hasNextPage,
            hasPrevPage: res.hasPrevPage,
            page: res.page,
          });

          const sortedEvents = res.docs.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
          setEvents(sortedEvents);
        } else {
          throw new Error("В ближайшее время гонок не ожидается...");
        }
      })
      .catch((error) => setError(error));

  return (
    <>
      <Layout>
        <Container>
          <H2>Архив прошедших событий</H2>
          <Paginator>
            <div className="buttons">
              <button
                disabled={!paginator.hasPrevPage}
                onClick={() => getEvents(paginator.page - 1)}
              >
                PREW
              </button>
              <p>
                {paginator.page}/{Math.ceil(paginator.all / 10) || ""}
              </p>
              <button
                disabled={!paginator.hasNextPage}
                onClick={() => getEvents(paginator.page + 1)}
              >
                NEXT
              </button>
            </div>
          </Paginator>
        </Container>

        <CardList events={events} error={error} />
      </Layout>
    </>
  );
};

export default ArchivePage;
