import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import Layout from '../components/layout';

moment.locale('ru');

const DayCell = styled.div`
  height: calc((100vh - 135px) / 5);
  outline: 1px solid var(--color-black);
  padding: 0.5em;
  text-align: right;
  /* border-bottom: 1px solid var(--color-black); */
  display: table-cell;
  &:hover {
    background: var(--color-orange);
  }
`;

const Container = styled.div`
  width: 100%;
`;

const StyledCalendar = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-top: 1px solid var(--color-black);
  border-left: 1px solid var(--color-black);
`;

export default function Calendar() {
  const dateObject = new moment();
  // const weekdayshort = moment.weekdays();

  const firstDayOfMonth = () => {
    let firstDay = moment(dateObject).startOf('month').format('d');
    return firstDay;
  };

  const currentDay = () => {
    return dateObject.format('D');
  };

  let blanks = [];

  for (let i = 0; i < firstDayOfMonth(); i++) {
    blanks.push(<DayCell>{''}</DayCell>);
  }

  let daysInMonth = [];

  for (let d = 1; d <= dateObject.daysInMonth(); d++) {

    daysInMonth.push(
      <DayCell key={d}>{currentDay() === d ? 'today' : d}</DayCell>
    );
  }

  var totalSlots = [...blanks, ...daysInMonth];

  return (
    <Layout>
      <Container>
        {/* <h2>Календарь</h2> */}
        {/* {weekdayshort.map((day) => (
          <div key={day} className="week-day">
            {day}
          </div>
        ))} */}
        <StyledCalendar>{totalSlots}</StyledCalendar>
      </Container>
    </Layout>
  );
}
