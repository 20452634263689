import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import MainPage from "./pages/index";
import Login from "./pages/login";
import Archive from "./pages/archive";
import CreateEventForm from "./pages/add";
import { PrivateRoute } from "./utils/privateRoute";
import Calendar from "./components/calendar";
import DashBoard from "./pages/dashBoard";

import AuthContext from "./context/authContext";

function App() {
  const userContext = useContext(AuthContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    userContext.auth.onAuthStateChanged((authUser) => {
      authUser ? setUser(authUser) : setUser(null);
    });

  }, [user, userContext.auth]);

  return (
    <Router>
      <Switch>
        <PrivateRoute path='/create' component={CreateEventForm} />
        <PrivateRoute path='/dashboard' component={DashBoard} />
        {/* <PrivateRoute path="/profile" component={User} /> */}
        <Route path='/login' component={Login} />
        <Route path='/archive' component={Archive} />
        <Route path='/calendar' component={Calendar} />
        <Route path='/' component={MainPage} />
      </Switch>
    </Router>
  );
}

export default App;
